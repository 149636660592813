import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComoFunciona from "../components/comofunciona"
import Banner from "../components/banner"
import Diferenciais from "../components/diferenciais"
import ModelosCasas from "../components/modeloscasas"
import FormSimulacao from "../components/FormSimulacao"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Revo Brasil" />
      <Banner />
      <ComoFunciona />
      <Diferenciais />
      <ModelosCasas />
      <FormSimulacao />
    </Layout>
  )
}

export default IndexPage
