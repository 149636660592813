import React from "react"
import { FiChevronDown } from "react-icons/fi"
import { IoIosArrowRoundForward } from "react-icons/io"

// import { Container } from './styles';

export default function Banner() {
  return (
    <section
      id="inicio"
      class="fdb-block fdb-viewport bg-dark Banner"
      style={{
        backgroundImage: "url(banner.jpg)",
      }}
    >
      <div class="container align-items-center justify-content-center d-flex">
        <div class="row align-items-center text-center text-lg-left">
          <div class="col-12 col-md-8">
            <h1 className="display-4 font-weight-bold">
              Nós ajudamos você a construir a sua casa!
            </h1>
            <p class="h2 font-weight-normal">
              Construa sua casa a partir da planta, e saia do aluguel
            </p>
            <div class="mt-5 d-flex flex-column flex-md-row align-items-center">
              <a
                href="#simulacao"
                title="Faça a simulação agora"
                class="js-smooth btn btn-success px-4 py-2 text-uppercase d-inline-flex align-items-center"
              >
                Faça a simulação agora{" "}
                <IoIosArrowRoundForward className="ml-2" size={30} />
              </a>
              <a
                href="#comofunciona"
                title="Saiba mais"
                class="js-smooth mt-2 btn btn-link text-white ml-4 text-uppercase d-inline-flex align-items-center"
              >
                Saiba mais <FiChevronDown className="ml-2" size={25} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
