import React, { useState } from "react"
import {
  Field,
  Formik,
  Form,
  useFormikContext,
  FieldArray,
  ErrorMessage,
} from "formik"

import axios from "axios"
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io"
import MaskedInput from "react-text-mask"
import * as yup from "yup"

import MoneyField from "./MoneyField"
import { MASK_DATE, celphone } from "../utils/mask"

const steps = [
  "Minhas informações",
  "Informações do cônjuge",
  "Como será",
  "Contato",
]

const BotaoAvancar = ({ onClick }) => {
  return (
    <button
      className="btn btn-primary ml-md-auto d-flex align-items-center justify-content-center text-uppercase"
      type="button"
      onClick={onClick}
    >
      Avançar <IoIosArrowRoundForward className="ml-2" size={30} />
    </button>
  )
}

function Informacoes() {
  const { values, isSubmitting } = useFormikContext()
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <strong className="mb-3 d-block">
          Selecione as opções que melhor se aplicam a você
        </strong>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="carteira"
            id="carteira"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="carteira">
            Tenho 3 anos ou mais de registro em carteira
          </label>
        </div>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="outroimovel"
            id="outroimovel"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="outroimovel">Já tenho outro imóvel no meu nome</label>
        </div>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="outrofinan"
            id="outrofinan"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="outrofinan">Já tenho outro financiamento</label>
        </div>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="tenhofilho"
            id="tenhofilho"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="tenhofilho">Tenho filho(s) ou dependente(s)</label>
        </div>
      </div>

      <div className="col-12 col-md-6">
        <div className="form-group">
          <label htmlFor="tiporenda">Tipo de renda*</label>
          <Field
            as="select"
            name="tiporenda"
            id="tiporenda"
            className="form-control"
            disabled={isSubmitting}
          >
            <option value="trabalhoregistrado">Trabalho registrado</option>
            <option value="dono">Sou dono de uma empresa</option>
            <option value="autonomo">Sou autônomo</option>
            <option value="publico">Funcionário público</option>
          </Field>
        </div>

        <div className="form-group">
          {values.tiporenda === "trabalhoregistrado" && (
            <label htmlFor="holerite">Valor do holerite*</label>
          )}
          {values.tiporenda === "dono" && (
            <label htmlFor="holerite">Valor do prolabore*</label>
          )}
          {values.tiporenda === "autonomo" && (
            <label htmlFor="holerite">Valor da renda bruta comprovável*</label>
          )}
          {values.tiporenda === "publico" && (
            <label htmlFor="holerite">Valor da renda mensal*</label>
          )}
          <Field
            name="holerite"
            id="holerite"
            className="form-control"
            placeholder="Ex.: R$ 1000,00"
            disabled={isSubmitting}
            component={MoneyField}
          />
        </div>
        <div className="form-group">
          <label htmlFor="fgts">Valor do FGTS*</label>
          <Field
            name="fgts"
            id="fgts"
            placeholder="Ex.: R$ 1000,00"
            className="form-control"
            disabled={isSubmitting}
            component={MoneyField}
          />
        </div>
        <div className="form-group">
          <label htmlFor="nascimento">Data de nascimento</label>
          <Field
            name="nascimento"
            id="nascimento"
            placeholder="DD/MM/AAAA"
            className="form-control"
            disabled={isSubmitting}
            as={MaskedInput}
            mask={MASK_DATE}
          />
        </div>
      </div>
    </div>
  )
}

function Conjuge() {
  const { values, isSubmitting } = useFormikContext()

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <strong className="mb-3 d-block">
          Selecione as opções que melhor se aplicam a seu cônjuge ou
          companheiro(a)
        </strong>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="conjuge.carteira"
            id="carteira"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="carteira">
            Tenho 3 anos ou mais de registro em carteira
          </label>
        </div>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="conjuge.outroimovel"
            id="outroimovel"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="outroimovel">Já tenho outro imóvel no meu nome</label>
        </div>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="conjuge.outrofinan"
            id="outrofinan"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="outrofinan">Já tenho outro financiamento</label>
        </div>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="conjuge.tenhofilho"
            id="tenhofilho"
            className="form-check-input"
            disabled={isSubmitting}
          />
          <label htmlFor="tenhofilho">Tenho filho(s) ou dependente(s)</label>
        </div>
      </div>

      <div className="col-12 col-md-6">
        <div className="form-group">
          <label htmlFor="tiporenda">Tipo de renda*</label>
          <Field
            as="select"
            name="conjuge.tiporenda"
            id="tiporenda"
            className="form-control"
            disabled={isSubmitting}
          >
            <option value="trabalhoregistrado">Trabalho registrado</option>
            <option value="dono">Sou dono de uma empresa</option>
            <option value="autonomo">Sou autônomo</option>
            <option value="publico">Funcionário público</option>
          </Field>
        </div>

        <div className="form-group">
          {values.conjuge.tiporenda === "trabalhoregistrado" && (
            <label htmlFor="holerite">Valor do holerite*</label>
          )}
          {values.conjuge.tiporenda === "dono" && (
            <label htmlFor="holerite">Valor do prolabore*</label>
          )}
          {values.conjuge.tiporenda === "autonomo" && (
            <label htmlFor="holerite">Valor da renda bruta comprovável*</label>
          )}
          {values.conjuge.tiporenda === "publico" && (
            <label htmlFor="holerite">Valor da renda mensal*</label>
          )}
          <Field
            name="conjuge.holerite"
            id="holerite"
            className="form-control"
            placeholder="Ex.: R$ 1000,00"
            disabled={isSubmitting}
            component={MoneyField}
          />
        </div>

        <div className="form-group">
          <label htmlFor="fgts">Valor do FGTS*</label>
          <Field
            name="conjuge.fgts"
            id="fgts"
            placeholder="Ex.: R$ 1000,00"
            className="form-control"
            component={MoneyField}
            disabled={isSubmitting}
          />
        </div>
        <div className="form-group">
          <label htmlFor="nascimento">Data de nascimento</label>
          <Field
            name="conjuge.nascimento"
            id="nascimento"
            placeholder="DD/MM/AAAA"
            className="form-control"
            disabled={isSubmitting}
            as={MaskedInput}
            mask={MASK_DATE}
          />
        </div>
      </div>
    </div>
  )
}

const regioes = [
  {
    regiao: "Rio Preto - Região de Talhados",
    empreendimentos: [
      "RES.SETVALLEY 2 (SÃO JOSÉ DO RIO PRETO)",
      "SETVALLEY (SJRP)",
    ],
  },
  {
    regiao: "Rio Preto - Região de Schmitt",
    empreendimentos: [
      "PQ.DAS AMORAS II (SJRP)",
      "PQ.DAS AMORAS II (COMERCIAIS) (SJRP)",
      "PQ.DAS AMORAS (SJRP)",
    ],
  },
  {
    regiao: "Rio Preto - Região norte",
    empreendimentos: [
      "RES.MIRANTE (SJRP)",
      "RES.SAO THOMAZ II (SJRP)",
      "RES.CALIFORNIA (SJRP)",
      "RES.JORGE RODRIGUES (SJRP)",
      "RES.MAIS VIVER (SJRP)",
    ],
  },
]

function ComoSera() {
  const { values, isSubmitting } = useFormikContext()

  return (
    <>
      <p className="text-dark">
        Selecione as regiões que tem interesse em construir sua casa
      </p>
      <FieldArray
        name="empreendimentos"
        render={arrayHelpers =>
          regioes.map((item, index) => (
            <div key={index}>
              <strong className="d-block mb-2">{item.regiao}</strong>
              <div className="row">
                {item.empreendimentos.map((empreendimento, index) => (
                  <div className="col-12 col-md-4 form-group" key={index}>
                    <div className="custom-control custom-checkbox">
                      <input
                        checked={values.empreendimentos.includes(
                          empreendimento
                        )}
                        type="checkbox"
                        name="empreendimentos"
                        id={empreendimento}
                        value={empreendimento}
                        className="custom-control-input"
                        disabled={isSubmitting}
                        onChange={e => {
                          if (e.target.checked) {
                            arrayHelpers.push(empreendimento)
                          } else {
                            const idx = values.empreendimentos.indexOf(
                              empreendimento
                            )
                            arrayHelpers.remove(idx)
                          }
                        }}
                      />
                      <label
                        className="cursor-pointer custom-control-label"
                        htmlFor={empreendimento}
                      >
                        {empreendimento}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        }
      />

      <div className="form-group">
        <label htmlFor="outroempreendimento">
          Não encontrou o bairro/cidade? Informe abaixo onde deseja morar
        </label>
        <Field
          name="outroempreendimento"
          id="outroempreendimento"
          className="form-control"
          placeholder="Ex.: São José do Rio Preto - Redentora"
          disabled={isSubmitting}
        />
      </div>
    </>
  )
}

function Contato() {
  const { isSubmitting } = useFormikContext()
  return (
    <div className="row">
      <div className="col-12 col-md-8 mx-auto">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="nome">Nome*</label>
              <Field
                name="nome"
                id="nome"
                className="form-control"
                placeholder="Seu nome*"
                disabled={isSubmitting}
              />
              <ErrorMessage
                name="nome"
                component="small"
                className="text-danger"
              />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label htmlFor="telefone">Telefone*</label>
              <Field
                type="tel"
                id="telefone"
                name="telefone"
                className="form-control"
                placeholder="Ex.: (00) 0 0000-0000"
                disabled={isSubmitting}
                as={MaskedInput}
                mask={celphone}
              />
              <ErrorMessage
                name="telefone"
                component="small"
                className="text-danger"
              />
            </div>
            {/* <div className="form-group custom-control custom-checkbox">
              <Field
                type="checkbox"
                id="recebercontato"
                name="recebercontato"
                className="custom-control-input"
                disabled={isSubmitting}
              />
              <label
                className="custom-control-label cursor-pointer"
                htmlFor="recebercontato"
              >
                Aceito receber contato pelo WhatsApp
              </label>
            </div> */}
          </div>

          <div className="col-12">
            <div className="form-group">
              <label htmlFor="email">E-mail*</label>
              <Field
                id="email"
                name="email"
                type="email"
                className="form-control"
                disabled={isSubmitting}
                placeholder="Ex.: seuemail@dominio.com.br"
              />
              <ErrorMessage
                name="email"
                component="small"
                className="text-danger"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const schemaSimulacao = yup.object().shape({
  // tipo: yup.string().required("Selecione um tipo"),
  email: yup
    .string()
    .email("Informe um e-mail válido")
    .required("Informe um e-mail"),
  nome: yup.string().required("Informe o seu nome"),
  telefone: yup
    .string()
    .test("format", "Informe um telefone válido", val => {
      if (!val) return
      let clearVal = val.replace(/\D*/g, "")
      return clearVal.length === 10 || clearVal.length === 11
    })
    .required("Informe o seu telefone"),
})

const simulacaoInicial = {
  tipo: "",
  tiporenda: "trabalhoregistrado",
  empreendimentos: [],
  email: "",
  nome: "",
  telefone: "",
  conjuge: {
    tiporenda: "trabalhoregistrado",
  },
}

const CODIGO_EMPREENDIMENTO = 11398

export default function FormSimulacao() {
  const [currentStep, setCurrentStep] = useState(-1)

  const [isSuccess, setSuccess] = useState(false)
  const [haveError, setHaveError] = useState(false)

  const enviarForm = async (values, { resetForm }) => {
    let identificador = "Site Revo | Conversão"
    const TOKEN_RDSTATION = "7acc31c6eb48a154eea0be20761e4816"
    const URL_RDSTATION = "https://www.rdstation.com.br/api/1.3/conversions"

    setHaveError(false)
    setSuccess(false)

    const requestRDStation = {
      token_rdstation: TOKEN_RDSTATION,
      identificador,
      email: values.email,
      nome: values.nome,
      celular: values.telefone,
      website: "revobrasil.com.br",
      tags: CODIGO_EMPREENDIMENTO,
    }

    const { data } = await axios.post(URL_RDSTATION, requestRDStation)
    // const { response } = await axios.post("", {
    //   hotsite: [
    //     {
    //       empreendimento: CODIGO_EMPREENDIMENTO,
    //       name: values.nome,
    //       email: values.email,
    //       celular: values.telefone.replace(/\D.*/g, ""),
    //       contato: values.telefone.replace(/\D.*/g, ""),
    //       // estado: "SP",
    //       // cidade: "SÃO JOSÉ DO RIO PRETO",
    //       // interesse: "MONTAR UM NEGÓCIO",
    //       // observacao: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     },
    //   ],
    // })

    if (data.result === "success") {
      if (window.fbq) {
        window.fbq("track", "Lead")
      }
      if (window.gtag_report_conversion) {
        window.gtag_report_conversion()
      }

      resetForm(simulacaoInicial)
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 8000)
    } else {
      setHaveError(true)
    }
  }

  return (
    <section className="fdb-block py-3" id="simulacao">
      <div className="container py-2 py-md-5">
        <div className="row">
          <div className="col-12 col-md-7 mx-auto">
            <div className="card shadow-lg p-1 p-md-4">
              <div className="card-body">
                  <div className="text-center">
                    <h2 className="mb-0 font-weight-bold">Não deixe o seu <u className="text-info">sonho</u> para depois!</h2>
                    <p className="lead">
                      Fale conosco agora e descubra a melhor forma de construir
                      a sua casa
                    </p>
                  </div>

                <Formik
                  onSubmit={enviarForm}
                  initialValues={simulacaoInicial}
                  validationSchema={schemaSimulacao}
                >
                  {({ values, setFieldValue, isSubmitting }) => {
                    const maxStep = values.tipo === "solteiro" ? 2 : 3

                    return (
                      <Form noValidate>
                        <div className="row justify-content-center align-items-stretch mb-3">
                          {currentStep > -1 && (
                            <>
                              <div className="col-12 col-md-8 align-items-stretch">
                                <button
                                  type="button"
                                  disabled={isSubmitting}
                                  className={`card card-body w-100 mb-2 d-flex align-items-center justify-content-center h-100 ${values.tipo ===
                                    "casado" && "bg-primary text-light"}`}
                                  onClick={() =>
                                    setFieldValue("tipo", "casado")
                                  }
                                >
                                  Sou Casado(a)
                                </button>
                              </div>
                              <div className="col-12 col-md-4">
                                <button
                                  type="button"
                                  disabled={isSubmitting}
                                  className={`card card-body w-100 mb-2 d-flex align-items-center justify-content-center h-100 ${values.tipo ===
                                    "companheiro" && "bg-primary text-light"}`}
                                  onClick={() =>
                                    setFieldValue("tipo", "companheiro")
                                  }
                                >
                                  Solteiro(a) - Compondo renda com meu
                                  companheiro(a)
                                </button>
                              </div>
                              <div className="col-12 col-md-4">
                                <button
                                  type="button"
                                  disabled={isSubmitting}
                                  className={`card card-body w-100 mb-2 d-flex align-items-center justify-content-center h-100 ${values.tipo ===
                                    "solteiro" && "bg-primary text-light"}`}
                                  onClick={() =>
                                    setFieldValue("tipo", "solteiro")
                                  }
                                >
                                  Solteiro(a) - Utilizando somente a minha renda
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        <Contato />

                        <div className="col-12 text-center d-flex flex-column align-items-center">
                          {haveError && (
                            <div className="text-danger mr-2">
                              Erro ao enviar os dados, tente novamente mais
                              tarde
                            </div>
                          )}
                          {isSuccess && (
                            <div className="alert alert-success text-center mx-auto">
                              Obrigado por seu contato, retornaremos o mais
                              breve possível :)
                            </div>
                          )}
                          <button
                            className="btn btn-lg btn-success py-2 px-5 text-uppercase"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            {isSubmitting ? "Enviando..." : "Enviar"}
                          </button>
                        </div>

                        {values.tipo && (
                          <>
                            <ul className="bg-light px-4 py-4 py-md-3 rounded nav d-flex flex-column flex-md-row mb-3 justify-content-around">
                              {steps
                                .filter(
                                  item =>
                                    values.tipo !== "solteiro" ||
                                    (values.tipo === "solteiro" &&
                                      item !== steps[1])
                                )
                                .map((item, index) => (
                                  <li
                                    className={`${currentStep === index &&
                                      "text-primary"} mr-3 mb-1 d-flex align-items-center`}
                                  >
                                    <span className="counter">{index + 1}</span>
                                    &nbsp;-&nbsp;{item}
                                    {currentStep === index && (
                                      <span className="ml-2 badge badge-success">
                                        você está aqui
                                      </span>
                                    )}
                                  </li>
                                ))}
                            </ul>

                            {currentStep === 0 && <Informacoes />}

                            {values.tipo === "solteiro" ? (
                              <>
                                {currentStep === 1 && <ComoSera />}
                                {currentStep === 2 && <Contato />}
                              </>
                            ) : (
                              <>
                                {currentStep === 1 && <Conjuge />}
                                {currentStep === 2 && <ComoSera />}
                                {currentStep === 3 && <Contato />}
                              </>
                            )}

                            <div className="d-flex flex-column flex-md-row justify-content-md-between">
                              {currentStep > 0 && (
                                <button
                                  className="btn btn-outline-primary mb-2 mb-md-0 d-flex align-items-center justify-content-center text-uppercase"
                                  type="button"
                                  disabled={isSubmitting}
                                  onClick={() =>
                                    setCurrentStep(currentStep - 1)
                                  }
                                >
                                  <IoIosArrowRoundBack
                                    className="mr-2"
                                    size={30}
                                  />{" "}
                                  Voltar
                                </button>
                              )}

                              {currentStep === maxStep ? (
                                <div className="d-flex align-items-center">
                                  {haveError && (
                                    <div className="text-danger mr-2">
                                      Preencha os dados corretamente
                                    </div>
                                  )}
                                  <button
                                    className="btn btn-success py-2 px-5 text-uppercase"
                                    disabled={isSubmitting}
                                    type="submit"
                                  >
                                    {isSubmitting ? "Enviando..." : "Enviar"}
                                  </button>
                                </div>
                              ) : (
                                <BotaoAvancar
                                  onClick={() =>
                                    setCurrentStep(currentStep + 1)
                                  }
                                />
                              )}
                            </div>
                          </>
                        )}
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
